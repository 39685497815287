body {
  background-image: 
    radial-gradient(circle, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.9) 70%, rgba(0, 0, 0, 1) 100%),
    url('../images/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  margin: 0;
}

.App {
  text-align: center;
}

input {
  background-color: rgba(255, 255, 255, 0.2);
  color: #e5e7eb !important;
  font-size: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.75rem;
  border-radius: 0.5rem;
}

input::placeholder {
  color: #e5e7eb;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

li:last-child {
  border-bottom: none;
}

.fade-in {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInEase 0.5s ease-in-out forwards;
}

.fade-in-delayed {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInEase 0.5s ease-in-out 0.5s forwards; /* Add a 0.25s delay */
}

@keyframes fadeInEase {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.ease-out {
  animation: easeOut 0.25s ease-in-out backwards !important;
}

@keyframes easeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}